.ccontact {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ccontact__title {
    text-align: center;
}

.ccontact__input {
    width: 100%;
    border: 1px solid var(--secondary-color);
    padding: 1rem;
    border-radius: 20px;
    outline: none;
}

.ccontact__danger-text {
    font-size: 0.8rem;
}