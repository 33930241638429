.sidebar {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    overflow-y: auto;
    padding: 1.5rem 2rem;
}

.sidebar__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sidebar__link {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1.2rem;
    text-decoration: none;
    color: #555555;
    cursor: pointer;
    transition: 0.5ms;
}

.sidebar__link:hover {
    opacity: 0.8;
}

.sidebar__link p {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sidebar__link-item {
    padding: 10px;
    border-radius: 10px;
    background: #5E63661A;
}

.sidebar__link .sidebar__count {
    background: var(--secondary-color);
    border-radius: .75rem;
    color: #fff;
    flex-shrink: 0;
    font-size: .875rem;
    font-weight: 500;
    height: 1.5rem;
    line-height: 1.5625rem;
    min-width: 1.5rem;
    padding: 0 .4375rem;
    text-align: center;
}

.sidebar__plus {
    background: var(--secondary-color);
    color: #ffffff;
    border-radius: 50%;
}

.sidebar__link-exit {
    color: var(--danger-color);
}