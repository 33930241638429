.ctitle {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
}

.ctitle span {
    color: var(--secondary-color);
}

@media only screen and (max-width: 1097px) {
    .ctitle {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {}