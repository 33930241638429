* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

:root {
  --primary-color: #336699;
  --secondary-color: #FA7758;
  --danger-color: #ff0000;
  --success-color: #4CAF50;
  --disabled-color: #757575;
  --selected-item-color: #e6e6e6;
  --info-color: #a8a8a8;
}

.wrapper {
  padding: 1rem 20rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success__text {
  color: var(--success-color);
}

.danger__text {
  color: var(--danger-color);
}

@media only screen and (max-width: 1920px) {
  .wrapper {
    padding: 1rem 15rem;
  }
}

@media only screen and (max-width: 1440px) {
  .wrapper {
    padding: 1rem 4rem;
  }
}

@media only screen and (max-width: 1097px) {
  .wrapper {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 1rem;
  }
}