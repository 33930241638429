.cpartners {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.cpartners__img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    cursor: pointer;
    transition: 0.5ms;
}

.cpartners__img:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1097px) {
    .cpartners {
        flex-wrap: wrap;
    }

    .cpartners__img {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .cpartners__img {
        width: 55px;
        height: 55px;
    }
}