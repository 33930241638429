.about__intro {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
}

.about__intro-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about__intro-title {
    font-size: 3rem;
}

.about__intro-title span {
    color: var(--primary-color);
}

.about__intro-title-span {
    color: var(--secondary-color) !important;
}

.about__intro-description {
    font-size: 1.5rem;
    color: #323232;
    line-height: 1.5;
}

.about__intro-btn {
    width: 300px;
    background: var(--secondary-color);
    color: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.5ms;
}

.about__intro-btn:hover {
    opacity: 0.8;
}

.about__intro-img {
    width: 600px;
}

.about__partners-title {
    font-size: 2rem;
    color: #342F4E80;
    margin-bottom: 1rem;
}

.about__mission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.about__mission-img {
    width: 700px;
}

.about__mission-title {
    font-size: 2rem;
    color: #342F4E;
    margin-bottom: 3rem;
}

.about__mission-title p {
    color: var(--primary-color);
}

.about__mission-title p span {
    color: var(--secondary-color);
}

.mission__content {
    margin: 1rem 0;
}


.mission__content-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.mission__content-description {
    font-size: 14px;
    color: #161C2D;
    line-height: 1.5;
}

@media only screen and (max-width: 1097px) {
    .about__intro {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }
    
    .about__intro-title {
        font-size: 2rem;
    }
    
    .about__intro-description {
        font-size: 1.2rem;
    }

    .about__intro-img {
        width: 500px;
    }

    .about__partners-title {
        font-size: 1.5rem;
    }

    .about__mission {
        flex-direction: column;
    }

    .about__mission-img {
        width: 600px;
    }

    .about__mission-title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .mission__content-title {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .about__intro-title {
        font-size: 1.5rem;
    }
    
    .about__intro-description {
        font-size: 1rem;
    }

    .about__intro-img {
        width: 300px;
    }

    .about__mission-img {
        width: 350px;
    }

    .about__mission-title {
        margin-bottom: 1rem;
    }
}