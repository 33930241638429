.clevel {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.clevel__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--secondary-color);
}

.clevel__description {
    line-height: 1.5;
    color: #323232;
}


.clevel__img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #ECECEC;
    margin: 10px 0;
}

.clevel__img img {
    width: 150px;
}

.clevel__percent {
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width: 1097px) {
    .clevel {
        max-width: 100%;
        gap: 10px;
    }

    .clevel__title {
        font-size: 1.2rem;
        margin-bottom: 5px;
    }

    .devider {
        margin: 5px 0;
    }

    .clevel__img img {
        width: 100px;
    }

    .clevel__percent {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 767px) {
    .clevel {
        gap: 5px;
    }

    .clevel__img img {
        width: 100px;
    }

    .clevel__percent {
        font-size: 2.5rem;
    }
}