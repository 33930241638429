.ccard {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ccard__image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -1rem;
}

.ccard__image img {
    width: 200px;
    height: 200px;
}

.ccard__description {
    color: #32323280;
    line-height: 1.5;
}