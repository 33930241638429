.home__intro {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: space-between;
    padding-top: 8rem;
}

.home__intro-content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.home__intro-title {
    font-size: 3rem;
    line-height: 1.3;
}

.home__intro-description {
    font-size: 1.5rem;
    color: rgb(109, 109, 109);
}

.home__btn {
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5ms;
}

.home__btn:hover {
    opacity: 0.8;
}

.home__intro-button {
    width: 250px;
}

.home__intro-image {
    width: 600px;
}

.home__stats-title {
    font-size: 2rem;
    font-weight: bold;
    color: #342F4E;
    margin: 1rem 0;
}

.home__stats-items {
    display: flex;
    justify-content: space-between;
}

.home__stats-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 20px;
    color: white;
}

.home__stats-icon {
    display: flex;
    justify-content: flex-end;
}

.home__stats-icon img {
    width: 100px;
}

.home__socials {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home__socials-title {
    font-size: 2rem;
    font-weight: bold;
    color: #342F4E;
}

.home__socials-subtitle {
    color: rgb(109, 109, 109);
}

.home__socials-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    border-radius: 20px;
    background-color: #F1F6FB;
}

.home__socials-details {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 1rem;
}

.home__socials-image {
    width: 150px;
}

.home__socials-icons {
    display: flex;
    gap: 1rem;
}

.home__socials-icon {
    width: 50px;
}

.home__socials-description {
    font-size: 1.2rem;
    color: rgb(109, 109, 109);
    line-height: 1.5;
    text-align: justify;
}

.home__socials-button {
    width: 300px;
}

.home__jivo-img {
    width: 100%;
}

.home__jivo-mobile-img {
    width: 100%;
    display: none;
}

.home__integration {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home__integration-title {
    font-size: 2rem;
    font-weight: bold;
    color: #342F4E;
}

.home__integration-subtitle {
    font-size: 1.2rem;
    color: rgb(109, 109, 109);
    line-height: 1.5;
}

.home__integration-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home__integration-image {
    width: 500px;
}

.home__integration-brands-image {
    width: 500px;
}

.home__integration-details {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
}

.contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 20px;
    background: #F1F6FB;
}

.contact__image {
    width: 250px;
}

.contact__title {
    font-size: 3rem;
    color: #342F4E;
    margin: 1rem 0;
}

.contact__form {
    display: flex;
    gap: 1rem;
}

.contact__input {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 10px;
    border: none;
    outline: none;
}

.home__security-title {
    font-size: 2rem;
    color: #342F4E;
    margin: 1rem 0;
}

.home__security-description {
    font-size: 1.2rem;
    color: rgb(109, 109, 109);
    line-height: 1.5;
}

.home__security-features {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;
    margin: 1rem 0;
    background: var(--secondary-color);
}

.home__security-feature {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1rem;
    padding: 1rem;
    border-radius: 20px;
    background-color: white;
}

.home__security-feature-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home__security-feature-image img {
    width: 150px;
    height: 150px;
}

.home__security-feature-title {
    color: #342F4E;
}

.home__security-feature-description {
    color: rgb(109, 109, 109);
    line-height: 1.5;
}

.contact__button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

@media only screen and (max-width: 767px) {
    .home__intro {
        flex-direction: column;
        padding-top: 5rem;
    }

    .home__intro-content {
        gap: 1rem;
    }

    .home__intro-title {
        font-size: 2rem;
        line-height: 1;
    }

    .home__intro-description {
        font-size: 1rem;
    }

    .home__btn {
        padding: 1rem;
        font-size: 1rem;
    }

    .home__intro-button {
        width: 100%;
        margin: 1rem 0;
    }

    .home__intro-image {
        width: 350px;
    }

    .home__stats-title {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }

    .home__stats-items {
        flex-direction: column;
        gap: 1rem;
    }

    .home__stats-icon img {
        width: 150px;
    }

    .home__socials {
        gap: 0.5rem;
    }

    .home__socials-title {
        font-size: 1.5rem;
    }

    .home__socials-content {
        flex-direction: column;
        gap: 2rem;
    }

    .home__socials-details {
        width: 100%;
    }

    .home__jivo-img {
        display: none;
    }

    .home__jivo-mobile-img {
        display: block;
    }

    .home__socials-image {
        width: 150px;
    }

    .home__socials-description {
        font-size: 1rem;
    }

    .home__socials-button {
        width: 100%;
    }

    .home__integration-title {
        font-size: 1.5rem;
    }

    .home__integration-subtitle {
        font-size: 1rem;
    }

    .home__integration-content {
        gap: 1rem;
        flex-direction: column;
    }

    .home__integration-image {
        width: 300px;
    }

    .home__integration-brands-image {
        width: 300px;
    }

    .contact {
        flex-direction: column;
    }

    .contact__image {
        width: 150px;
    }

    .contact__title {
        font-size: 1rem;
        margin: 0.5rem 0;
    }

    .contact__input {
        font-size: 1rem;
    }

    .home__security-title {
        font-size: 1.5rem;
    }

    .home__security-description {
        font-size: 1rem;
    }

    .home__security-features {
        flex-direction: column;
        align-items: center;
    }

    .home__security-feature {
        width: 300px;
    }

    .home__security-feature-image img {
        width: 150px;
        height: 150px;
    }
}