.partner__intro {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 8rem;
}

.partner__operator {
    width: 350px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.partner__intro-img {
    width: 50%;
}

.partner__intro-img img {
    width: 100%;
}

.partner__intro-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    padding: 2rem ;
    width: 50%;
    height: 85vh;
    background: var(--secondary-color);
    color: #ffffff;
}

.partner__intro-title {
    font-size: 3rem;
}

.partner__intro-amount {
    font-size: 4rem;
}

.partner__intro-btn {
    width: 400px;
    background: #ffffff;
    color: var(--secondary-color);
    padding: 1rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.5ms;
}

.partner__intro-btn:hover {
    opacity: 0.8;
}

.level__title {
    font-size: 2.5rem;
}

.level__description {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #32323280;
    margin: 2rem 0;
}

.clevel__container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.partner__support {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.partner__support-title {
    font-size: 2.5rem;
    margin: 1rem 0;
}

.partner__support-description {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #32323280;
}

.partner__support-details {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 50px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.partner__support-phone {
    display: flex;
    align-items: center;
    gap: 10px;
}

.partner__support-phone a {
    color: var(--secondary-color);
    text-decoration: none;
}

.partner__support-email {
    display: flex;
    align-items: center;
    gap: 10px;
}

.partner__support-email a {
    color: var(--secondary-color);
    text-decoration: none;
}

.partner__support-icon {
    background: var(--secondary-color);
    color: #ffffff;
    font-size: 2rem;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.partner__features {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.partner__feature {
    width: 50%;
}

.partner__features p {
    line-height: 1.5;
    color: #32323280;
    margin: 2rem 0;
}

@media only screen and (max-width: 1097px) {
    .partner__intro {
        padding-top: 5rem;
    }
    
    .partner__operator {
        width: 270px;
    }

    .partner__intro-content {
        height: 60vh;
    }
    
    .partner__intro-title {
        font-size: 2rem;
    }
    
    .partner__intro-amount {
        font-size: 3rem;
    }
    
    .partner__intro-btn {
        width: 300px;
    }

    .level__title {
        font-size: 1.5rem;
    }

    .level__description {
        font-size: 1rem;
    }

    .partner__features {
        flex-wrap: wrap;
    }

    .partner__feature {
        width: 100%;
    }

    .partner__features p {
        margin: 1rem 0;
    }
}

@media only screen and (max-width: 767px) {
    .partner__intro {
        padding-top: 3rem;
    }
    
    .partner__operator {
        width: 150px;
    }

    .partner__intro-content {
        padding: 10px;
        height: 28vh;
    }
    
    .partner__intro-title {
        font-size: 1rem;
    }
    
    .partner__intro-amount {
        font-size: 1rem;
    }
    
    .partner__intro-btn {
        padding: 10px;
        width: 100px;
        font-size: 10px;
    }

    .clevel__container {
        flex-wrap: wrap;
    }

    .partner__support {
        flex-wrap: wrap;
        gap: 1rem;
    }

    .partner__support-title {
        font-size: 2rem;
    }

    .partner__support-description {
        font-size: 1rem;
    }

    .partner__support-details {
        width: 100%;
        padding: 20px;
    }

    .partner__support-icon {
        font-size: 1.5rem;
    }
}