.auth__header {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
}

.auth__header-title {
    font-size: 2rem;
    color: var(--secondary-color);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}

.auth__header-title span {
    color: var(--primary-color);
}

@media only screen and (max-width: 1097px) {
    .auth__header-title {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {}