.header {
    width: 100%;
    height: 76px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    z-index: 101;
}

.header__actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header__title {
    color: var(--secondary-color);
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
}

.header__title span {
    color: var(--primary-color);
}

.header__link {
    text-decoration: none;
    color: #000000;
    transition: 0.5ms;
    margin: 0 1.5rem;
}

.header__link:hover {
    color: var(--secondary-color);
}

.header__search-icon {
    font-size: 2rem;
    cursor: pointer;
    transition: 0.5ms;
}

.header__search-icon:hover {
    color: var(--secondary-color);
}

.header__register-btn {
    padding: 10px;
    background: var(--primary-color);
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    transition: 0.5ms;
}

.header__register-btn:hover {
    opacity: 0.8;
}

.header__burger {
    cursor: pointer;
    font-size: 2rem;
    display: none;
}

.header__mobile-open {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    background: var(--primary-color);
    position: absolute;
    top: 0;
    right: 0;
    padding: 2rem;
    z-index: 101;
}

.header__close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    font-size: 2rem;
    color: var(--secondary-color);
}

@media only screen and (max-width: 1097px) {
    .header__title {
        font-size: 1.5rem;
    }

    .header__link {
        font-size: 14px;
        margin: 0 0.8rem;
    }

    .header__search-icon {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {
.header__actions {
        display: none;
    }

    .header__links {
        display: none;
    }

    .header__link {
        color: #ffffff;
        margin: 0;
    }

    .header__burger {
        display: block;
    }

    .header__register-mobile-btn {
        background: var(--secondary-color);
    }
}