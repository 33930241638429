.capplication {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 20px;
    background: #F1F6FB;
}

.capplication__img {
    width: 250px;
}

.capplication__title {
    font-size: 3rem;
    color: #342F4E;
    margin: 1rem 0;
}

.capplication__form {
    display: flex;
    gap: 1rem;
}

.capplication__input {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 10px;
    border: none;
    outline: none;
}

.capplication__button {
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5ms;
}

.capplication__button:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 1097px) {
    .capplication {
        flex-wrap: wrap;
    }

    .capplication__img {
        width: 200px;
    }

    .capplication__title {
        font-size: 2rem;
        margin: 0.5rem 0;
    }

    .capplication__input {
        font-size: 1rem;
    }

    .capplication__button {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .capplication {
        flex-wrap: wrap;
    }

    .capplication__img {
        width: 150px;
    }

    .capplication__title {
        font-size: 1.2rem;
        margin: 0.5rem 0;
    }
}