.cinput__container {
    position: relative;
}

.cinput {
    width: 100%;
    padding: 1rem 2rem;
    background: #EFF1F999;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    outline: none;
}

.cinput__icon {
    display: flex;
    font-size: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
}

.cinput__icon-start {
    left: 1px;
}

.cinput__icon-end {
    right: 1px;
}

.cinput__icon-eye {
    cursor: pointer;
}