.ctab {
    width: 100%;
}

.ctab__tabs {
    width: 100%;
    display: flex;
    background: #F1F6FB;
    border-radius: 10px;
}

.ctab__tab {
    width: 100%;
    padding: 1rem;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: 0.5ms;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1rem;
}

.ctab__tab:hover {
    opacity: 0.8;
}

.ctab__tab-active {
    background: var(--secondary-color);
    color: #ffffff;
}