.tarif__intro {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding-top: 8rem;
}

.tarif__intro-img {
    width: 350px;
}

.tarif__intro-title {
    font-size: 4rem;
}

.tarif__intro-description {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 1rem;
    color: #323232;
}

.tarif__plans {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.tarif__plans-header {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.tarif__plans-title {
    font-size: 2.5rem;
}

.tarif__plans-tab {
    width: 30%;
}

.tarif__plan-list {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.tarif__plan {
    width: 300px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
}

.tarif__plan-price {
    display: flex;
    align-items: center;
    gap: 5px;
}

.tarif__plan-price-amount {
    font-size: 2rem;
    color: var(--secondary-color);
}

.tarif__plan-price-period {
    font-size: 0.8rem;
    color: #323232;
}

.tarif__plan-name {
    font-size: 1.5rem;
    color: var(--secondary-color);
    font-weight: bold;
}

.tarif__plan-description {
    font-size: 0.8rem;
    line-height: 1.2;
    color: #323232;
}

.tarif__plan-features {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tarif__plan-feature {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.8rem;
}

.tarif__plan-feature img {
    padding: 5px;
    background: #F1F6FB;
    border-radius: 50%;
}

.tarif__faqs-title {
    font-size: 2rem;
    margin: 1rem 0 3rem 0;
}

.tarif__faqs-list {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.tarif__faqs-item {
    width: 45%;
    margin-bottom: 1rem;
}

.tarif__faqs-question {
    font-size: 1.5rem;
    line-height: 1.5;
}

.tarif__faqs-answer {
    line-height: 2;
    color: #323232;
}

.tarif__functions {
    overflow-x: auto;
}

.tarif__table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.tarif__table td,
th {
    border: none;
    text-align: left;
    padding: 1rem;
}

.tarif__table th {
    background: var(--secondary-color);
    color: #ffffff;
}

.tarif__table tr:nth-child(even) {
    background-color: #D4D4D880;
}

.tarif__icon {
    width: 30px;
}

.tarif__cell {
    text-align: center !important;
    font-weight: bold;
}

.tarif__cell p {
    margin: 10px 0;
}

.tarif__feature {
    text-align: center !important;
}

.tarif__button {
    width: 100%;
    padding: 10px;
    background: #ffffff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 50px;
    cursor: pointer;
    transition: 0.5ms;
}

.tarif__button:hover {
    background: var(--secondary-color);
    color: #ffffff;
}

@media only screen and (max-width: 1097px) {
    .tarif__intro {
        padding-top: 6rem;
        flex-wrap: wrap;
        justify-content: center;
    }

    .tarif__intro-img {
        width: 300px;
    }

    .tarif__intro-title {
        font-size: 3rem;
    }

    .tarif__intro-description {
        font-size: 1.2rem;
        line-height: 1.2;
        margin-top: 0.5rem;
    }

    .tarif__plans-header {
        gap: 1rem;
    }

    .tarif__plans-title {
        font-size: 1.5rem;
    }

    .tarif__plans-tab {
        width: 40%;
    }

    .tarif__plan-list {
        flex-wrap: wrap;
    }

    .tarif__plan {
        width: 250px;
    }

    .tarif__plan-price-amount {
        font-size: 1.5rem;
    }

    .tarif__plan-name {
        font-size: 1rem;
    }

    .tarif__plan-description {
        font-size: 0.7rem;
    }

    .tarif__plan-feature {
        font-size: 0.7rem;
    }

    .tarif__faqs-title {
        font-size: 1.5rem;
    }

    .tarif__faqs-item {
        width: 100%;
    }

    .tarif__faqs-question {
        font-size: 1rem;
    }

    .tarif__faqs-answer {
        line-height: 1.5;
    }
}

@media only screen and (max-width: 767px) {
    .tarif__intro {
        padding-top: 5rem;
    }

    .tarif__intro-img {
        width: 200px;
    }

    .tarif__intro-title {
        font-size: 2rem;
    }

    .tarif__intro-description {
        font-size: 1rem;
    }

    .tarif__plan {
        width: 100%;
    }
}