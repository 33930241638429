.register__wrapper {
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EFF1F999;
}

.register {
    min-width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    text-align: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 2rem;
}

.register__header {
    margin: 1rem 0;
}

.register__subtitle {
    color: #8B8D97;
    margin: 5px 0;
}

.register__restore {
    text-align: right;
    cursor: pointer;
    transform: 0.5ms;
}

.register__restore:hover {
    opacity: 0.8;
}

.register__login-link {
    color: #8B8D97;
    text-decoration: none;
    transition: 0.5ms;
}

.register__login-link span {
    color: #000000;
}

.register__login-link:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 767px) {
    .register__wrapper {
        align-items: flex-start;
        padding: 2rem 0;
    }

    .register {
        gap: 1rem;
        padding: 1rem;
    }
}