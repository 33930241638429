.cbtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    color: #ffffff;
    gap: 5px;
    padding: 1rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5ms;
}

.cbtn__primary {
    background-color: var(--primary-color);
}

.cbtn__secondary {
    background-color: var(--secondary-color);
}

.cbtn:disabled {
    background-color: #d3d3d3;
    color: #000000;
    cursor: not-allowed;
}

.cbtn:hover {
    opacity: 0.8;
}

.cbtn:disabled:hover {
    opacity: 1;
}

.cbtn__icon {
    font-size: 1rem;
    display: flex;
}